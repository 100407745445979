import React from 'react';
import { Bell, Calendar, Wrench, Home } from 'lucide-react';

interface Announcement {
  title: string;
  date: string;
  content: string;
  category: string;
  icon: keyof typeof icons;
}

const icons = {
  bell: Bell,
  calendar: Calendar,
  tool: Wrench,
  home: Home
};

export default function Announcements() {
  const announcements: Announcement[] = [
    {
      title: "Holiday Decorating Contest Winners",
      date: "December 2024",
      category: "Community Event",
      icon: "home",
      content: "Congratulations to 21985 Sundowners for winning the 2024 Holiday Home Decorating Contest! Thank you to all participants for making our community festive and bright this holiday season."
    },
    {
      title: "Holiday Office Closures",
      date: "December 2024",
      category: "Office Update",
      icon: "calendar",
      content: "The Keystone offices will be closed on: Christmas Eve (Dec 24), Christmas Day (Dec 25), New Year's Eve (Dec 31), and New Year's Day (Jan 1, 2024)."
    },
    {
      title: "Community Projects Update",
      date: "December 2024",
      category: "Maintenance",
      icon: "tool",
      content: "The first phase of the street repairs project has been completed as of November 1, 2024. The next step will be re-striping throughout. Once a date has been confirmed, an eblast will be sent out and notices will be posted."
    },
    {
      title: "Exterior Painting Notice",
      date: "December 2024",
      category: "Maintenance",
      icon: "home",
      content: "Several homes inside Sycamore Creek have visible weather and sun damage. With the upcoming rainy season, it is recommended to schedule exterior painting now. Please submit an architectural application before making any exterior improvements."
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Community Announcements</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Stay informed about important updates and news from your Sycamore Creek community.
          </p>
        </div>

        <div className="space-y-6">
          {announcements.map((announcement, index) => {
            const IconComponent = icons[announcement.icon];
            return (
              <div key={index} className="bg-gradient-to-r from-indigo-50 to-white rounded-lg p-6 shadow-sm">
                <div className="flex items-start gap-4">
                  <div className="bg-indigo-100 rounded-full p-3 mt-1">
                    <IconComponent className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-xl font-semibold">{announcement.title}</h3>
                      <span className="text-sm text-indigo-600 font-medium bg-indigo-50 px-3 py-1 rounded-full inline-flex items-center justify-center">
                        {announcement.category}
                      </span>
                    </div>
                    <div className="text-sm text-gray-500 mb-3">
                      {announcement.date}
                    </div>
                    <p className="text-gray-700 leading-relaxed">
                      {announcement.content}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}